.text {
    padding: 30px 0;
    color: #000000;
    font-family: 'Poppins', sans-serif;
    font-size: 21px;
    width: 100%;
    font-weight: 700;
}

.title {
    font-size: 40px;
}

.description {
    padding: 30px 0;
}

.images {
    display: flex;

    @media screen and (max-width: 992px) {
        display: none;
    }
}

.images_mobile {
    display: none;

    @media screen and (max-width: 992px) {
        display: flex;

    }
}