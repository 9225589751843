.wrapper {
    border-top: 2px solid black;
    border-bottom: 2px solid black;
    justify-content: space-between;
}

.inner{
    display: flex;
    flex-direction: column;
    margin: 0 auto;
}

.title {

    font-family: 'Poppins', sans-serif;
    text-transform: capitalize;
    color: #000;
    font-size: 50px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: center;

    padding: 20px;

    @media screen and (max-width: 571px) {
        font-size: 30px;
    }

}


.button {
    box-sizing: border-box;
    margin: 20px auto;
    width: 500px;
    height: 100px;
    transition: all 0.5s ease-out;

    img {
        width: 100%;
        height: 100%;
        border-radius: 16px;
    }

    @media screen and (max-width: 571px) {
        width: 80%;
        height: 75px;
    }
}