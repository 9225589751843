.body {
  background-color: #ffffff;

}

.wrapper {
  font-family: "Poppins", sans-serif;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 20px 0 0 0;

}

.text {
  padding: 30px 0 30px 0;
  font-size: 18px;
  color: #000000;
}

.logo {
  display: flex;
  width: 150px;
  padding: 5px;

  img {
    width: 100%;
    height: 100%;
  }

  @media screen and (max-width: 768px) {
    margin-bottom: 40px;
    width: 100px;
  }
}

.rows {
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 890px) {
    flex-direction: column;
  }
}

.row {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 5px;
  width: fit-content;
  height: auto;
  font-size: 15px;

  a {
    margin-bottom: 15px;
  }
}

a {
  color: #000000;
  text-decoration: none;
}

.button {
  width: 350px;
  height: 70px;
  margin: 10px auto 0 auto;
  transition: all 0.5s ease-out;

  @media screen and (max-width: 890px) {
    width: 200px;
    height: 50px;
  }

  img {
    width: 100%;
    height: 100%;
    border-radius: 16px;
  }

  &:hover {
    width: 360px;
    height: 75px;
    margin: 10px auto 0 auto;
  }
}

.line {
  height: 1px;
  background-color: #000000;
  margin: 10px 0;
}

.copyright {
  padding: 10px 0;
  font-size: 14px;
}