.accordion{
    background-color: black;
    width: 100%;
    border-radius: 20px;
    padding: 15px;

    .main{
        display: flex;
    }
    .title{
        display: flex;
        color: white;
    }

    .plus{
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-left: auto;
        display: flex;
        justify-content: center;
        justify-items: end;
        background-color: white;
        display: flex;
        font-size: 30px;
        transition: 0.4s;
        resize: none;
        cursor: pointer;
    }
    .plus:hover{
        background-color: rgb(165, 165, 165);
    }
    .description{
        color: white;
        max-height: 0;
        font-size: 18px;
        overflow: hidden;
        transition: all ease-in-out .5s;
    }
    .descriptionOpen{
        max-height: 1000px;
        margin-top: 20px;
        display: block;
    }
}